import { Project, State, UserRole } from '@flow/db'
import {
    CalendarIcon,
    FolderIcon,
    HomeIcon,
    IdentificationIcon,
    QueueListIcon,
    SignalIcon,
} from '@heroicons/react/24/outline'
import {
    ActivityLogIcon,
    ArchiveIcon,
    DashboardIcon,
    GearIcon,
    LayoutIcon,
    ListBulletIcon,
    RowsIcon,
} from '@radix-ui/react-icons'
import {
    createFileRoute,
    Link,
    LinkProps,
    Outlet,
    redirect,
    useParams,
    useRouter,
    useSearch,
} from '@tanstack/react-router'
import { CalendarRange, LineChart, User } from 'lucide-react'
import { forwardRef, ReactNode, useMemo } from 'react'

import { Tracker } from '../components/Board/Tracker'
import { BreadCrumb } from '../components/Navbar/BreadCrumb'
import { NotificationPopup } from '../components/Notifications/NotificationPopup'
import { ProtectedElement } from '../components/Primitives/Protected'
import { ScrollArea } from '../components/Primitives/ScrollArea'
import { ModeToggle } from '../components/Primitives/ThemeSwitcher'
import { UserMenu } from '../components/UserMenu/UserMenu'
import { useAuth } from '../contexts/auth.context'
import { BreadCrumbs } from '../utils/breadCrumbs'
import { cn } from '../utils/cn'
import { BUILD } from '../utils/env'
import { trpc } from '../utils/trpc'

const navigation: {
    name: string
    href: string
    icon: React.ForwardRefExoticComponent<
        React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
            title?: string
            titleId?: string
        } & React.RefAttributes<SVGSVGElement>
    >
}[] = [
    { name: 'Dashboard', href: '/', icon: HomeIcon },
    { name: 'Inbox', href: '/inbox', icon: QueueListIcon },
    { name: 'Projects', href: '/projects', icon: FolderIcon },
    { name: 'Activity', href: '/activity', icon: SignalIcon },
    { name: 'Holidays', href: '/holidays', icon: CalendarIcon },
]

export const AuthLayout = () => {
    const { user } = useAuth()
    const router = useRouter()

    const { epicId, cycleId } = useSearch({
        strict: false,
    })
    const { projectId, issueId } = useParams({
        strict: false,
    })

    const { data: projects } = trpc.project.getAll.useQuery()

    const { crumb, isAdminPage } = useMemo(() => {
        const param = [...router.state.matches].pop()
        const crumb = param?.context?.breadCrumb
        const isAdminPage =
            crumb === BreadCrumbs.AdminDashboard ||
            crumb === BreadCrumbs.AdminUsers ||
            crumb === BreadCrumbs.AdminProjects ||
            crumb === BreadCrumbs.AdminTimeline

        return {
            crumb,
            isAdminPage,
        }
    }, [router.state.matches])

    const currentProject = useMemo(
        () => projects?.find((p) => p.id === projectId),
        [projectId, projects],
    )

    return (
        <div className="flex h-screen">
            <div className="flex w-64 flex-col border-r">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6">
                    <ScrollArea horizontal={false}>
                        <div className="mt-4 flex w-full justify-between" />
                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col gap-y-2">
                                <li>
                                    <ul className="w-full space-y-1">
                                        {navigation.map((item) => (
                                            <li key={item.name}>
                                                <Link
                                                    to={item.href}
                                                    activeProps={{
                                                        className: 'bg-secondary text-primary',
                                                    }}
                                                    inactiveProps={{
                                                        className:
                                                            'text-primary hover:text-primary hover:bg-primary-foreground',
                                                    }}
                                                    className="group flex items-center gap-x-2 rounded-md px-2 py-1 text-xs leading-6"
                                                >
                                                    <item.icon
                                                        className="size-4 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                        <AdminPages isAdminPage={isAdminPage} />
                                    </ul>
                                </li>
                                <li className="w-full">
                                    <div className="flex flex-col space-y-2">
                                        {currentProject && crumb && (
                                            <div>
                                                <Link
                                                    to={`/project/${currentProject.id}`}
                                                    className={cn(
                                                        'text-sm w-full rounded-lg py-1 px-2  items-center hover:bg-primary-foreground hover:text-primary transition-all [&[data-state=open]>svg]:rotate-90 group flex',
                                                    )}
                                                >
                                                    <span className="rounded-sm bg-brand-purple px-1 py-[.2px] text-muted dark:text-primary">
                                                        {currentProject.id}
                                                    </span>
                                                    <span className="ml-1 flex flex-1 justify-start text-xs font-semibold">
                                                        {currentProject.name}
                                                    </span>
                                                </Link>
                                                <ProjectPages project={currentProject} />
                                            </div>
                                        )}
                                        <h4 className="mb-2 text-sm text-muted-foreground">
                                            Your projects
                                        </h4>
                                        <div className="flex w-full flex-col space-y-2">
                                            {user?.projects.map((project) => {
                                                return (
                                                    <Link
                                                        key={project.id}
                                                        to={`/project/${project.id}`}
                                                        className={cn(
                                                            'text-sm w-full rounded-lg py-1 px-2  items-center hover:bg-primary-foreground hover:text-primary group flex',
                                                            {
                                                                'bg-primary-foreground text-primary':
                                                                    projectId === project.id,
                                                            },
                                                        )}
                                                    >
                                                        <span className="rounded-sm bg-brand-purple px-1 py-[.2px] text-muted dark:text-primary">
                                                            {project.id}
                                                        </span>
                                                        <span className="ml-1 flex flex-1 justify-start text-xs font-semibold text-muted-foreground">
                                                            {project.name}
                                                        </span>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </ScrollArea>
                </div>
                <div className="w-full px-6 py-2 text-[10px]">build: {BUILD.slice(0, 10)}</div>
            </div>

            <div className="flex size-full flex-col overflow-hidden">
                <header className="flex h-14 w-full items-center border-b-DEFAULT px-8 text-xs text-primary">
                    <BreadCrumb
                        crumb={crumb as any}
                        projectId={projectId}
                        issueId={issueId}
                        epicId={epicId}
                        cycleId={cycleId}
                    />
                    <div className="flex w-full flex-1 justify-end space-x-1">
                        <Tracker projectId={projectId} issueId={issueId} />
                        <NotificationPopup />
                        <ModeToggle />
                        <UserMenu />
                    </div>
                </header>
                <main className="flex h-full space-x-2 overflow-hidden">
                    <ScrollArea className="flex-1 p-8" style={{ height: 'calc(100vh) -56px' }}>
                        <Outlet />
                    </ScrollArea>
                </main>
            </div>
        </div>
    )
}

const AdminPages = ({ isAdminPage }: { isAdminPage: boolean }) => {
    return (
        <ProtectedElement authorizedRoles={[UserRole.ADMIN]}>
            <Link
                to={'/admin'}
                className={cn(
                    'text-xs w-full gap-x-2 rounded-md px-2 py-1 leading-6 items-center hover:bg-primary-foreground hover:text-primary transition-all [&[data-state=open]>svg]:rotate-90 group flex',
                )}
            >
                <IdentificationIcon className="size-4 shrink-0" aria-hidden="true" />
                Admin
            </Link>
            {isAdminPage && (
                <div className="text-xs text-muted-foreground">
                    <CustomNavLink
                        to={`/admin`}
                        icon={<DashboardIcon className="size-3 text-yellow-400" />}
                        label="Dashboard"
                    />
                    <CustomNavLink
                        to={`/admin/users`}
                        icon={<User className="size-3 text-purple-400" />}
                        label="Users"
                    />
                    <CustomNavLink
                        to={`/admin/projects`}
                        icon={<FolderIcon className="size-3 text-red-400" />}
                        label="Projects"
                    />
                    <CustomNavLink
                        to={`/admin/timeline`}
                        icon={<LineChart className="size-3 text-green-400" />}
                        label="Timeline"
                    />
                </div>
            )}
        </ProtectedElement>
    )
}

interface UserProjectsProps {
    project: Project
}
const ProjectPages = ({ project }: UserProjectsProps) => {
    return (
        <div className="text-xs text-muted-foreground">
            <CustomNavLink
                to={`/project/$projectId`}
                params={{ projectId: project.id }}
                icon={<LayoutIcon className="size-3 text-yellow-400" />}
                label="Board"
            />
            <CustomNavLink
                to={`/project/$projectId/timeline`}
                params={{ projectId: project.id }}
                icon={<LineChart className="size-3 text-green-400" />}
                label="Timeline"
            />
            <CustomNavLink
                to={`/project/$projectId/list`}
                params={{ projectId: project.id }}
                icon={<RowsIcon className="size-3 text-orange-500" />}
                label="List"
            />
            <CustomNavLink
                to={`/project/$projectId/epics`}
                params={{ projectId: project.id }}
                icon={<ActivityLogIcon className="size-3 text-violet-500 " />}
                label="Epics"
            />
            <CustomNavLink
                to={`/project/$projectId/cycles`}
                params={{ projectId: project.id }}
                icon={<CalendarRange className="size-3 text-red-400" />}
                label="Cycles"
            />
            <CustomNavLink
                to={`/project/$projectId/list`}
                search={{ states: [State.BACKLOG] }}
                params={{ projectId: project.id }}
                icon={<ListBulletIcon className="size-3 text-blue-400" />}
                label="Backlog"
            />
            <CustomNavLink
                to={`/project/$projectId/list`}
                params={{ projectId: project.id }}
                search={{ states: [State.ARCHIVE] }}
                icon={<ArchiveIcon className="size-3 text-orange-400" />}
                label="Archive"
            />
            <ProtectedElement authorizedRoles={[UserRole.ADMIN]}>
                <CustomNavLink
                    to={`/project/$projectId/settings`}
                    params={{ projectId: project.id }}
                    icon={<GearIcon className="size-3 text-primary" />}
                    label="Settings"
                    className="flex cursor-pointer items-center space-x-1"
                />
            </ProtectedElement>
        </div>
    )
}

interface CustomNavLinkProps extends LinkProps {
    icon: ReactNode
    label: string
    className?: string
}

// Forward ref to make CustomNavLink compatible with ref forwarding
const CustomNavLink = forwardRef<HTMLAnchorElement, CustomNavLinkProps>((props, ref) => {
    const { icon, label, className, ...linkProps } = props
    return (
        <Link
            {...linkProps}
            ref={ref}
            activeOptions={{ exact: true, includeSearch: true }}
            activeProps={{ className: 'text-primary bg-primary-foreground' }}
            className={cn(
                'flex cursor-pointer items-center space-x-1 px-2 py-1 rounded-md',
                className,
            )}
        >
            {icon}
            <span>{label}</span>
        </Link>
    )
})

export const Route = createFileRoute('/_auth')({
    component: AuthLayout,
    beforeLoad: ({ context }) => {
        if (!context.auth) {
            throw redirect({
                to: '/login',
            })
        }
    },
})

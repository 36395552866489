import { CheckCircledIcon, Half2Icon } from '@radix-ui/react-icons'
import { UserTimeEntryByProject } from '@schema'
import { Link } from '@tanstack/react-router'
import { CircleIcon } from 'lucide-react'

import { cn } from '../../utils/cn'
import { formatDuration } from '../../utils/date'

interface EntryCardProps {
    entry: UserTimeEntryByProject
    timeSpent: number
    numberOfEntries: number
    inProgress: boolean
}

export const EntryCard = ({ entry, timeSpent, numberOfEntries, inProgress }: EntryCardProps) => {
    return (
        <Link
            key={entry.id}
            className="flex flex-col rounded border-[.1px] border-muted-foreground/80 bg-secondary bg-gradient-to-tl from-muted to-secondary p-2 text-xs text-primary dark:bg-gradient-to-tl dark:from-muted/20 dark:to-secondary"
            to="/project/$projectId/issue/$issueId"
            params={{
                projectId: entry.issue.projectId,
                issueId: entry.issue.id,
            }}
        >
            <p>{entry.issue.name}</p>
            <div className="mt-2 flex items-center justify-between">
                <div className="flex items-center space-x-1">
                    {entry.issue.status.state === 'IN_PROGRESS' && (
                        <Half2Icon className="text-yellow-400" />
                    )}
                    {entry.issue.status.state === 'DONE' && (
                        <CheckCircledIcon className="text-green-400" />
                    )}
                    {entry.issue.status.state === 'UNSTARTED' && (
                        <CircleIcon className="text-purple-400" />
                    )}
                    <p>{entry.issue.status.name}</p>

                    {numberOfEntries > 1 ? (
                        <kbd className="pointer-events-none ml-2 inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                            {numberOfEntries}x
                        </kbd>
                    ) : null}
                </div>
                <div
                    className={cn('rounded-md px-2 py-1 text-xs text-muted', {
                        'bg-green-400': inProgress,
                        'bg-primary': !inProgress,
                    })}
                >
                    {timeSpent > 0 ? formatDuration(timeSpent) : 'I/P'}
                </div>
            </div>
        </Link>
    )
}

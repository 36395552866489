import { State } from '@flow/db'
import { getRouteApi } from '@tanstack/react-router'

import { useProject } from '../../contexts/project.context'
import { Button } from '../Primitives/Button'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../Primitives/Select'
import { StatusBox } from './StateBox'

const PATH = '/_auth/project/$projectId/list'
const routeApi = getRouteApi(PATH)

export const TaskFilters = () => {
    const { project } = useProject()
    const navigate = routeApi.useNavigate()
    const { search } = routeApi.useLoaderDeps()

    const onEpicChange = (epicId: string | null) => {
        navigate({
            search: (search) => ({
                ...search,
                epicId: epicId || undefined,
            }),
        })
    }

    const onCycleChange = (cycleId: string | null) => {
        navigate({
            search: (search) => ({
                ...search,
                cycleId: cycleId || undefined,
            }),
        })
    }

    const onStatusChange = (states: State[]) => {
        navigate({
            search: (search) => ({
                ...search,
                states,
            }),
        })
    }

    return (
        <div className="flex space-x-4">
            <Button
                variant="outline"
                onClick={() =>
                    navigate({
                        search: {},
                    })
                }
            >
                X
            </Button>
            <StatusBox
                selectedStates={project.statuses.filter((p) => {
                    if (!p.state) return false
                    return search.states?.includes(p.state)
                })}
                stateOptions={project.statuses}
                onChange={(state) =>
                    onStatusChange(state.filter((s) => s.state !== null).map((s) => s.state!))
                }
            />
            {project.epics.length > 0 && (
                <div className="w-[200px]">
                    <Select onValueChange={onEpicChange}>
                        <SelectTrigger className=" bg-background">
                            <SelectValue>
                                {project.epics?.find((m) => m.id === search.epicId)?.name ||
                                    'Select epic'}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value={null as any}>No epic</SelectItem>
                                {project.epics.map((status) => {
                                    return (
                                        <SelectItem key={status.id} value={status.id}>
                                            {status.name}
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            )}
            {project.cycles.length > 0 && (
                <div className="w-[200px]">
                    <Select onValueChange={onCycleChange}>
                        <SelectTrigger className=" bg-background">
                            <SelectValue>
                                {project.cycles?.find((m) => m.id === search.cycleId)?.name ||
                                    'Select cycle'}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value={null as any}>No cycle</SelectItem>
                                {project.cycles.map((status) => {
                                    return (
                                        <SelectItem key={status.id} value={status.id}>
                                            {status.name}
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            )}
        </div>
    )
}
